import React from "react"
import tw, { styled } from "twin.macro"
import "styled-components/macro"
import { graphql } from "gatsby"
import { getInput } from "../components/Inputs"
import _ from "lodash"
import useForm from "../hooks/useForm"

import Layout from "../components/Layout"
import Header from "../components/Header"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const Container = styled.div`
  ${tw`p-5`}
`
const ContentListContainer = styled.div`
  ${tw`mt-5`}
`
const GridContainer = styled.div`
  ${tw`grid grid-cols-12 gap-2.5`}
`
const GridItem = styled.div`
  grid-column: ${props => `span ${props.span}`};
`
const MarkdownDiv = styled.div`
  ${tw`prose max-w-full`}
`
const Form = styled.form`
  ${tw`w-full mt-5`}
`
const SubmitButton = styled.button`
  ${tw`px-4 py-2 mt-1 bg-[#EC1F27] text-white font-bold rounded text-sm disabled:grayscale transition-[filter] duration-200`}
`

const PlayerApplicationPage = ({
  data: {
    markdownRemark: {
      frontmatter: { seo, title, formName, hero, tabs, labels, contentList },
    },
  },
  location,
}) => {
  const {
    handleChange,
    handleSubmit,
    submitted,
    error,
    loading,
    errorMessage,
  } = useForm({})
  const breakpoints = useBreakpoint()
  const isMidScreen =
    Object.values(breakpoints).reduce((a, b) => (b ? a + 1 : a), 0) > 1
  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
    >
      <Container>
        {title && <Header title={title} heroData={hero} tabsData={tabs} />}
        <ContentListContainer>
          {contentList.map((contentSection, index) => {
            const { type, markdown, input } = contentSection
            return (
              <React.Fragment key={index}>
                {type === "markdown" && (
                  <MarkdownDiv dangerouslySetInnerHTML={{ __html: markdown }} />
                )}
                {type === "form" && (
                  <Form name={formName} onSubmit={handleSubmit}>
                    <GridContainer>
                      {!_.isEmpty(input) &&
                        input.map((config, idx) => {
                          const {
                            inputType,
                            label,
                            options,
                            colSpan,
                            placeholder,
                            required,
                          } = config

                          return (
                            <GridItem
                              key={idx}
                              span={isMidScreen ? colSpan : 12}
                            >
                              {getInput({
                                handleChange,
                                inputType,
                                label,
                                options,
                                placeholder,
                                required,
                              })}
                            </GridItem>
                          )
                        })}
                      <GridItem
                        span={12}
                        tw="flex items-center justify-between"
                      >
                        <SubmitButton disabled={loading || submitted}>
                          {labels.submitBtn || "Send Form"}
                        </SubmitButton>
                        {error && (
                          <span tw="text-[#EC1F27]">{errorMessage}</span>
                        )}
                        {submitted && (
                          <span tw="text-black">
                            {labels.sentMessage || "Form successfully sent!"}
                          </span>
                        )}
                      </GridItem>
                    </GridContainer>
                  </Form>
                )}
              </React.Fragment>
            )
          })}
        </ContentListContainer>
      </Container>
    </Layout>
  )
}

export default PlayerApplicationPage

export const pageQuery = graphql`
  query PlayerApplicationPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          description
          title
        }
        title
        formName
        hero {
          pageImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          pageImagePosition
        }
        tabs {
          label
          url
          icon
        }
        labels {
          submitBtn
          sentMessage
        }
        contentList {
          type
          markdown
          input {
            inputType
            label
            required
            colSpan
            options
            placeholder
          }
        }
      }
    }
  }
`
