import React from "react"
import tw, { styled } from "twin.macro"
import _ from "lodash"

const InputWrapper = styled.div`
  ${tw`w-full relative`}
`
const InputLabel = styled.label`
  ${tw`text-[10px] px-4 absolute top-2 font-bold text-gray-400 uppercase`}
`
const StyledInput = styled.input`
  ${tw`w-full outline-0 pt-6 px-4 pb-1.5 text-sm border border-gray-300 rounded focus:invalid:border focus:invalid:border-[#EC1F27]`}
`
const StyledInputArea = styled.textarea`
  ${tw`w-full outline-0 pt-6 px-4 pb-1.5 text-sm border border-gray-300 rounded focus:invalid:border focus:invalid:border-[#EC1F27]`}
`

export const TextInput = ({
  name,
  handleChange,
  label,
  placeholder,
  required,
}) => (
  <InputWrapper>
    <InputLabel htmlFor={label}>{label}</InputLabel>
    <StyledInput
      required={required}
      name={name}
      onChange={handleChange}
      id={label}
      placeholder={placeholder}
    />
  </InputWrapper>
)

export const PhoneInput = ({
  name,
  handleChange,
  label,
  placeholder,
  required,
}) => {
  return (
    <InputWrapper>
      <InputLabel htmlFor={label}>{label}</InputLabel>
      <StyledInput
        required={required}
        name={name}
        onChange={handleChange}
        id={label}
        placeholder={placeholder}
        type="tel"
        pattern="[(\[][0-9]{3}[)\]]-[0-9]{3}-[0-9]{4}"
      />
    </InputWrapper>
  )
}

export const EmailInput = ({
  name,
  handleChange,
  label,
  placeholder,
  required,
}) => (
  <InputWrapper>
    <InputLabel htmlFor={label}>{label}</InputLabel>
    <StyledInput
      required={required}
      name={name}
      onChange={handleChange}
      id={label}
      placeholder={placeholder}
      type="email"
      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
    />
  </InputWrapper>
)

export const DateInput = ({
  name,
  handleChange,
  label,
  placeholder,
  required,
}) => (
  <InputWrapper>
    <InputLabel htmlFor={label}>{label}</InputLabel>
    <StyledInput
      required={required}
      name={name}
      onChange={handleChange}
      id={label}
      placeholder={placeholder}
      type="date"
      pattern="\d{4}-\d{2}-\d{2}"
    />
  </InputWrapper>
)

export const TextAreaInput = ({
  name,
  handleChange,
  label,
  placeholder,
  required,
}) => (
  <InputWrapper>
    <InputLabel htmlFor={label}>{label}</InputLabel>
    <StyledInputArea
      required={required}
      name={name}
      onChange={handleChange}
      id={label}
      placeholder={placeholder}
    />
  </InputWrapper>
)

const StyledSelect = styled.select`
  ${tw`w-full outline-0 pt-6 px-4 pb-1.5 text-sm border border-gray-300 rounded focus:invalid:border focus:invalid:border-[#EC1F27]`}
`

export const SelectInput = ({
  name,
  handleChange,
  label,
  placeholder,
  required,
  options,
}) => (
  <InputWrapper>
    <InputLabel htmlFor={label}>{label}</InputLabel>
    <StyledSelect
      required={required}
      name={name}
      onChange={handleChange}
      id={label}
      placeholder={placeholder}
    >
      <option value="">_</option>
      {!_.isEmpty(options) &&
        options.map((op, idx) => (
          <option key={idx} value={op}>
            {op}
          </option>
        ))}
    </StyledSelect>
  </InputWrapper>
)

export const getInput = ({
  handleChange,
  inputType,
  label,
  options,
  placeholder,
  required,
}) => {
  const name = _.kebabCase(label)

  switch (inputType) {
    case "text":
      return (
        <TextInput
          handleChange={handleChange}
          label={label}
          placeholder={placeholder}
          required={required}
          name={name}
        />
      )
    case "textarea":
      return (
        <TextAreaInput
          handleChange={handleChange}
          label={label}
          placeholder={placeholder}
          required={required}
          name={name}
        />
      )
    case "select":
      return (
        <SelectInput
          handleChange={handleChange}
          label={label}
          placeholder={placeholder}
          required={required}
          name={name}
          options={options}
        />
      )
    case "date":
      return (
        <DateInput
          handleChange={handleChange}
          label={label}
          placeholder={placeholder}
          required={required}
          name={name}
        />
      )
    case "email":
      return (
        <EmailInput
          handleChange={handleChange}
          label={label}
          placeholder={placeholder}
          required={required}
          name={name}
        />
      )
    case "phone":
      return (
        <PhoneInput
          handleChange={handleChange}
          label={label}
          placeholder={placeholder}
          required={required}
          name={name}
        />
      )
    default:
      return <div />
  }
}
